import React, { useState, useEffect } from 'react';
import user from './assets/images/user.jpg';

function Author({my}) {
  

  return (
    <div className="author">
        <img width="50px" height="50px" src={user}/>
        <h3>Jeff Suchland</h3>
    </div>
  );
}

export default Author;