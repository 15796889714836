import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBirthdayCake, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import {surl, csrfFromCookie, getCookie} from './Lib';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import me from './assets/images/me.jpg';

function Profile({my}) {
  let tone_choices = {
    '': 'Not Set',
    "m": 'Mystical and Enigmatic',
    'e': 'Empowering and Inspirational',
    'p': 'Practical and Grounded'
  }
  let tone_value = tone_choices[my.forecast_tone];
  const rows = [
    { name: 'Birthday', value: my.birth_day },
    { name: 'Birth Place', value: my.birth_place },
    { name: 'Sign', value: my.sign },
    { name: 'Tone', value: tone_value },
  ];
  

  return (
    <main>
      <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
      <Container maxWidth="sm">
        <div className="textBox">
          <div className="image">
            <img className="pfp" src={me} alt="Profile Picture"></img>
          </div>
          <Typography variant="h6" gutterBottom>
              My Profile
          </Typography> 
          <div className="text dataBoxes">
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="links">
                <Link to="/profile/edit">
                  <Button variant="contained" size="large">
                    Edit
                  </Button>
                </Link>
            </div>
          </div>
        </div>
        </Container>
      </Paper>
    </main>
  );
}

export default Profile;

