import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';

import {surl, csrfFromCookie, getCookie} from './Lib';


function Echo() {
  const [xval, setXval] = useState('');
  const [yval, setYval] = useState('');
  const [zval, setZval] = useState('');
  const [error, setError] = useState('');
  const [result, setResult] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
    	const args = new URLSearchParams({xval, yval, zval}).toString();

        await fetch(surl('/api/echo/?' + args), {  
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
        });
    }
    catch (error) {
      console.error('Error:', error);
      setError('An error occurred during echo');
    }

    try {
      const response = await fetch(surl('/api/echo/'), {  
        method: 'POST',
	credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
	  'X-CSRFToken' : csrfFromCookie(),
        },
        body: JSON.stringify({ xval, yval, zval }),
      });

      if (response.ok) {
        const data = await response.json();
	console.log('echo ', data);
	setResult( JSON.stringify(data) );
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Echo failed'); 
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred during echo');
    }
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <h2>Echo</h2>
      Result: {result}
      <p/>
      {error && (  // Conditionally render error message
        <div className="error-message">
          {error} 
        </div>
      )}
      <input type="text" placeholder="xval" value={xval} onChange={(e) => setXval(e.target.value)} />
      <input type="text" placeholder="yval" value={yval} onChange={(e) => setYval(e.target.value)} />
      <input type="text" placeholder="zval" value={zval} onChange={(e) => setZval(e.target.value)} />
      <button type="submit">Echo</button>
    </form>
  );
}

export default Echo;

