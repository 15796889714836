import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

import {server_post, DateStr, Error} from './Lib';
import ReactMarkdown from 'react-markdown';

function Fetch({setState,setError,forecast, my, when, setMy}) {
	const fetch = async() => {
		let data = null, error = '';

		({data, error} = await server_post('/api/fetch/', 
					{kind:'d', when:forecast.dt_from}));

		if (error)
			setError(error);
		else {
			my.forecasts[when] = data.forecast;
			setMy(my);

			setState('Pending');
		}
	}

	return (
	<div>
		<Button onClick={fetch} variant="contained">
			Fetch
		</Button>
	</div>
	);
}

function Pending({setState,setError,forecast, my, when, setMy}) {
	const [sec, setSec] = useState(0);

	const poll = async() => {
		let data = null, error = '';

		console.log('Pending.poll', when, sec);

		({data, error} = await server_post('/api/forecast/get/', 
					{when, fid:forecast.id}));

		if (error)
			setError(error);
		else {
			if (data.forecast.state == 'Active') {
				my.forecasts[when] = data.forecast;
				console.log("This one: ", data.forecast);
				setMy(my);

				setState('Active');
			}
			else {
				setSec( prevSec => prevSec + 1 );
			}
		}
	}

	useEffect(() => {
		console.log('Pending.useEffect', when, sec);

		const intervalId = setInterval(poll, 1000);

		return () => clearInterval(intervalId); 

	}, [sec]); 

	return (
	<div>
		<h1>Please wait...</h1>
		<h2>{sec} seconds </h2>
		This typically takes 10 seconds.
		<div className="spinner-container">
			<CircularProgress />
		</div>
	</div>
	);
}

function Show({setState,setError,forecast, my, when, setMy}) {
	const del = async() => {
		let data = null, error = '';

		({data, error} = await server_post('/api/forecast/delete/', 
					{when, fid:forecast.id}));

		if (error)
			setError(error);
		else {
			my.forecasts[when] = data.forecast;
			setMy(my);

			setState('None');
		}
	}

	return (
		<main>
			<Container maxWidth="sm">
				<div className="text dataBoxes" component={Paper}>
					<div className="forecast">
					<ReactMarkdown>
						{forecast.text}
					</ReactMarkdown>
					</div>
					<Button onClick={del} variant="contained">
						Delete
					</Button>
				</div>
			</Container>
		</main>
	);
}

function Forecast({my, setMy, when}) {
	let forecast, Comp;
	const [state, setState] = useState(getInitState());
	const [error, setError] = useState('');
	const [day, setDay] = useState(when);
	const navigate = useNavigate();

	useEffect( () => {
		if (when != day) {
			setDay(when);
			setState(getInitState());
		}
	});

	function getInitState() {
		let init_state;
		if (!my || !my.forecasts)
			init_state = 'NoLogin';
		else {
			forecast = my.forecasts[when];
			init_state = forecast.id ? forecast.state : 'None';
		}
		return init_state;
	}


	switch (state) {
	case 'NoLogin':
		navigate('/login');
		return null;

	case 'None':
		Comp = Fetch;
		break;

	case 'Pending':
		Comp = Pending;
		break;

	case 'Active':
		Comp = Show;
		break;
	}

	return (
		<main>
			<Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
				<Container maxWidth="sm">
					<div className="textBox">
						<div className="title">
							<Typography variant="h6" gutterBottom sx={{ textTransform: 'capitalize' }}>
								{when} - <DateStr date={forecast.dt_from} />
							</Typography>
						</div>
						<div className="text">
							<Error error={error} />
							<Comp setState={setState} setError={setError}
								forecast={forecast}
								my={my} when={when} setMy={setMy}
							/>
						</div>
					</div>
				</Container>
			</Paper>
		</main>
	);
}

export default Forecast;
