import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';

import {surl, getCsrfToken} from './Lib';


function Logout({ onLogout }) {
  const navigate = useNavigate();

  useEffect( () => {
	  onLogout();
	  navigate('/login'); 
  });

  return null;
}

export default Logout;

