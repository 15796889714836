import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';
import {server_post, Error} from './Lib';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import user from './assets/images/user.jpg';

function Login({ onLogin }) {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState('');
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		let data = null, error = '';

		({data, error} = await server_post('/api/login/', 
					{username, password}));

		console.log('Login', data, error);

		if (error)
			setError(error);
		else {
			onLogin(data.my);
			navigate('/dashboard'); 
		}
	}

	return (
		<main>
		<Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
		  <Container maxWidth="sm">
			<div className="textBox">
			  <div className="image">
				<img className="pfp logo" src={user} alt="StarSoul.me Logo" />
			  </div>
			  <Typography variant="h6" gutterBottom>
				Login
			  </Typography>
			  <div className="text">
				<form onSubmit={handleSubmit}>
				  <Error error={error} />
  
				  {/* Material UI TextFields */}
				  <Box sx={{ mb: 2 }}> {/* Add margin below the TextField */}
					<TextField
					  label="Username"
					  variant="outlined"
					  fullWidth 
					  value={username}
					  onChange={(e) => setUsername(e.target.value)}
					/>
				  </Box>
				  <Box sx={{ mb: 2 }}>
					<TextField
					  label="Password"
					  variant="outlined"
					  fullWidth
					  type="password"
					  value={password}
					  onChange={(e) => setPassword(e.target.value)}
					/>
				  </Box>
  
				  <Button type="submit" variant="contained">
					Login
				  </Button>
				</form>
			  </div>
			</div>
		  </Container>
		</Paper>
	  </main>
	);
}

export default Login;

