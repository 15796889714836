import * as React from 'react';
import { Link  } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import me from './assets/images/me.jpg';

function Footer() {
  return (
    <Box 
      component="footer" // Use Box to replicate the footer element
      sx={{ 
        position: 'fixed', // Keep the fixed positioning
        bottom: 0,
        width: '100%',
        backgroundColor: 'lightgray', // Add some background color (optional)
        padding: 2, // Add padding for better visual appearance
      }}
    >
      <Box 
        className="author" 
        sx={{ display: 'flex', alignItems: 'center' }} // Align items vertically
      >
        <Avatar alt="Jeff Suchland" src={me} />
        <Typography variant="h6" sx={{ marginLeft: 1 }}>
            Built By <a href="mailto:jeff@suchland.com">Jeff Suchland</a> / <a href="http://www.jeffsuchland.com">jeffsuchland.com</a>
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;