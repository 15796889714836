import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Container } from "@mui/material";
import me from './assets/images/me.jpg';


function MyChart({ svg }) {
  const enc = encodeURIComponent(svg);
  const durl = `data:image/svg+xml;charset=utf-8,${enc}`;

  return <img className="chart-svg" src={durl} alt="Chart" />;
}

function Dashboard({ my }) {
    const zodiacData = my.zodiac || {}; // Get zodiac data or empty object

    // Data for the Date & Location table
    const dateLocationData = [
        { Key: 'Name', Value: zodiacData.name },
        { Key: 'Date', Value: `${zodiacData.year}-${zodiacData.month}-${zodiacData.day}` },
        { Key: 'Time', Value: `${zodiacData.hour}:${zodiacData.minute}` },
        { Key: 'Location', Value: `${zodiacData.city}, ${zodiacData.nation}` },
        { Key: 'Coordinates', Value: `${zodiacData.lat}, ${zodiacData.lng}` },
    ];

    // Define table headers based on data structure
    const planetHeaders = ["Planet", "Sign", "Position", "House"];
    const houseHeaders = ["House", "Sign", "Position"];

// Table Components
const DateLocationTable = () => (
	<Box sx={{ my: 2 }}>
		<Typography variant="h6">Date & Location</Typography>
		<TableContainer component={Paper}>
			<Table size="small">
				<TableBody>
					{dateLocationData.map((row) => (
						<TableRow key={row.Key}>
							<TableCell>{row.Key}</TableCell>
							<TableCell>{row.Value}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	</Box>
);

const PlanetsTable = () => (
	<Box sx={{ my: 2 }}>
		<Typography variant="h6">Planets</Typography>
		<TableContainer component={Paper}>
			<Table size="small">
				<TableHead>
					<TableRow>
						{planetHeaders.map((header) => (
							<TableCell key={header}>{header}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{zodiacData.planets_list?.map((planet) => (
						<TableRow key={planet.name}>
							<TableCell sx={{ padding: '4px 8px' }}>{planet.name}</TableCell>
							<TableCell sx={{ padding: '4px 8px' }}>{planet.position.toFixed(4)}</TableCell> {/* Truncate to 4 decimals */}
							<TableCell sx={{ padding: '4px 8px' }}>{planet.sign}</TableCell>
							<TableCell sx={{ padding: '4px 8px' }}>{planet.house}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	</Box>
);

const HousesTable = () => (
	<Box sx={{ my: 2 }}>
		<Typography variant="h6">Houses</Typography>
		<TableContainer component={Paper}>
			<Table size="small">
				<TableHead>
					<TableRow>
						{houseHeaders.map((header) => (
							<TableCell key={header}>{header}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{zodiacData.houses_list?.map((house) => (
						<TableRow key={house.name}>
							<TableCell sx={{ padding: '4px 8px' }}>{house.name}</TableCell>
							<TableCell sx={{ padding: '4px 8px' }}>{house.sign}</TableCell>
							<TableCell sx={{ padding: '4px 8px' }}>{house.position.toFixed(4)}</TableCell> {/* Truncate to 4 decimals */}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	</Box>
);

return (
	<main>
		<Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
			<Container maxWidth="sm">
				<div className="textBox">
						<div className="image">

							<img className="pfp" src={me} alt="Profile Picture" />
						</div>
						<div className="title">
							{my.sign} {my.emoji}
						</div>
						<div className="text">
						<Box sx={{ width: '100%', overflow: 'hidden', my: 2 }}>
							<MyChart svg={my.svg} />
						</Box>

						<DateLocationTable />
                        <PlanetsTable />
                        <HousesTable />
					</div>
				</div>
			</Container>
		</Paper>
	</main>
    );
}

export default Dashboard;
