import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { server_post, Error } from './Lib';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

function Signup({ setMy }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { data, error } = await server_post('/api/signup/', {
      name, email, invite_code: inviteCode,
    });
    if (error) setError(error);
    else {
      setMy(data.my);
      navigate('/confirm');
    }
  };

  return (
    <main>
      <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
        <Container maxWidth="sm">
          <div className="textBox"> {/* Keep your existing container for styling */}
            <Typography variant="h6" gutterBottom>
              Signup
            </Typography>
            <form onSubmit={handleSubmit}>
              <Error error={error} />
              {/* Material UI TextFields with spacing */}
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  label="Invite Code"
                  variant="outlined"
                  fullWidth
                  value={inviteCode}
                  onChange={(e) => setInviteCode(e.target.value)}
                />
              </Box>
              {/* Submit Button */}
              <Button type="submit" variant="contained">
                Signup
              </Button>
            </form>
          </div>
        </Container>
      </Paper>
    </main>
  );
}

export default Signup;
