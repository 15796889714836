
function surl(url) {
	// if local server, use 8000 port for django, 
	//	if not same port on production
	const isLocal = window.location.hostname === 'localhost' ||
			window.location.hostname === '127.0.0.1';
	
	return isLocal?	'http://localhost:8000' + url : url;
}

const getCookie = (name) => {
	let cookieValue = null;
	if (document.cookie && document.cookie !== '') {
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			if (cookie.substring(0, name.length + 1) === name + '=') {
				cookieValue = decodeURIComponent(
					cookie.substring(name.length + 1));
				break;
			}
		}
	}
	return cookieValue;
};

function csrfFromCookie() {
	return getCookie('csrftoken');
}

async function getCsrfToken() {
	const response = await fetch(surl('/api/get_csrf_token/')); 
	const data = await response.json();
	return data.csrfToken;
}

async function server_post(url, formdata) {
	let data = null;
	let error = '';

	const csrfToken = getCookie('csrftoken');

	try {
		const response = await fetch(surl(url), {	
			method: 'POST',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken' : csrfToken, 
			},
			body: JSON.stringify(formdata),
		});

		if (response.ok) {
			data = await response.json();
		} 
		else {
			const errorData = await response.json();
			error = errorData.error || `${url} failed`; 
		}
		console.log(url, data, error)
	} 
	catch (err) {
		console.error('Error:', err);
		error = `An error occurred during ${url}: ${err}`;
	}
	console.log('server_post', url, formdata, data, error);
	return {data, error};
}

function DateStr({date}) {
	const dt = new Date(date);
	return (
	<span>
		{dt.toLocaleDateString('en-US',
			{year: 'numeric', month: 'long', day: 'numeric'})}
	</span>
	);
}

function Error({error}) {
	if (!error)
		return null;

	return (
	<div className="error-message">
		<b>Error:</b> {error} 
	</div>
	);
}

export {surl, getCsrfToken, getCookie, csrfFromCookie, server_post,
	DateStr, Error};
