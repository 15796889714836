import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { server_post } from './Lib';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import me from './assets/images/me.jpg';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'; // Import date adapter
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

function EditProfile({ my, setMy }) {
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [birth_place, setBirthPlace] = useState(my.birth_place);
  const [birthDay, setBirthDay] = useState(my.birth_day ? dayjs(my.birth_day) : null);
  const [forecast_tone, setForecastTone] = useState(my.forecast_tone);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formattedBirthDay = birthDay ? birthDay.format('YYYY-MM-DD') : ''; 
    const { data, error } = await server_post('/api/profile/edit/', { birth_day: formattedBirthDay, birth_place, forecast_tone });
    if (error) setError(error);
    else {
      console.log(data);
      setMy(data.my);
      navigate("/profile");
    }
  };
  const handleChange = (event) => {
    setForecastTone(event.target.value);
  };

  return (
    <main>
      <Container maxWidth="sm">
        <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
            <div className="textBox">
                <div className="image">
                    <img className="pfp" src={me} alt="Profile Picture"></img>
                </div>
                <Typography variant="h6" gutterBottom>
                    Edit Profile
                </Typography> 
            <div className="text dataBoxes">
                <form className="grid fixColumns" component={Paper} sx={{ gridTemplateColumns: '1fr' }} onSubmit={handleSubmit}>
                {error && <Alert severity="error">{error}</Alert>}
                <Grid container spacing={2} justifyContent="flex-start">
                    {/* Birthday */}
                    <Grid item xs={12}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            className='birthdayForm'
                            label="Birthday"
                            value={birthDay}
                            onChange={(newValue) => setBirthDay(newValue)}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                            inputFormat="YYYY-MM-DD" // Set input format explicitly
                        />
                      </LocalizationProvider>
                    </Grid>
                    {/* Birth Place */}
                    <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Birth Place"
                        type="text" 
                        id="birth_place" 
                        name="birth_place" 
                        value={birth_place} 
                        onChange={(e) => setBirthPlace(e.target.value)}
                    />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel id="forecast-tone">Forecast Tone</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            onChange={handleChange}
                            value={forecast_tone || ''} // Use '' as default value
                        >
                            <FormControlLabel value="" control={<Radio />} label="Not Set" /> 
                            <FormControlLabel value="m" control={<Radio />} label="Mystical and Enigmatic" />
                            <FormControlLabel value="e" control={<Radio />} label="Empowering and Inspirational" />
                            <FormControlLabel value="p" control={<Radio />} label="Practical and Grounded" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                </Grid>
                <Button type="submit" variant="contained">Update</Button>
                </form>
            </div>
          </div>
        </Paper>
      </Container>
    </main>
  );
}

export default EditProfile;
