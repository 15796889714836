import './App.css';
import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

import Signup from './Signup';
import Confirm from './Confirm'; 
import Login from './Login';
import Logout from './Logout';
import Echo from './Echo';
import Profile from './Profile';
import EditProfile from './EditProfile';
import Dashboard from './Dashboard';
import Forecast from './Forecast';
import Author from './Author';
import Footer from './Footer';

import logo from './assets/images/logo.jpg';
import user from './assets/images/user.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSun, faCalendarPlus, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

function PreLogin() {
  return (
    <AppBar position="static">
      <Toolbar>
        
        <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
          <img className="headerLogo" src={logo} alt="StarSoul.me Logo" style={{ height: 40 }} />
        </Link>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          StarSoul.me
        </Typography>
        <Button color="inherit" component={Link} to="/signup">Signup</Button>
        <Button color="inherit" component={Link} to="/login">Login</Button>
      </Toolbar>
    </AppBar>
  );
}

function PostLogin({ my }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static">
      <Toolbar>
        
        <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
          <img className="headerLogo" src={logo} alt="StarSoul.me Logo" style={{ height: 40 }} />
        </Link>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          StarSoul.me
        </Typography>
        <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
          <MenuIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem component={Link} to="/profile" onClick={handleMenuClose}>
            <FontAwesomeIcon icon={faUser} /> Profile
          </MenuItem>
          <MenuItem component={Link} to="/dashboard" onClick={handleMenuClose}>
            <FontAwesomeIcon icon={faSun} /> Dashboard
          </MenuItem>
          <MenuItem component={Link} to="/today" onClick={handleMenuClose}>
            <FontAwesomeIcon icon={faSun} /> Today
          </MenuItem>
          <MenuItem component={Link} to="/tomorrow" onClick={handleMenuClose}>
            <FontAwesomeIcon icon={faCalendarPlus} /> Tomorrow
          </MenuItem>
          <MenuItem component={Link} to="/logout" onClick={handleMenuClose}>
            <FontAwesomeIcon icon={faSignOutAlt} /> Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

function Home() {
  return (
    <main>
      <Paper elevation={3} sx={{ padding: 2, margin: 2 }}>
        <Container maxWidth="sm">
          <div className="textBox splash-container"> {/* A new container for better layout */}
            <div className="image">
              <img className="pfp" src={logo} alt="Starsoul.me Logo"></img>
            </div>
            <div className="title">
              <h1>Starsoul.me</h1>
              <p className="subtitle">Decode Your Cosmic Blueprint</p>
            </div>

            <div className="text-content"> 
              <p className="subtitle">Dive Deeper Than Your Sun Sign</p>
              <p>Tired of generic horoscopes? Starsoul.me unlocks the *true* power of astrology by exploring your *entire* birth chart. Discover the hidden influences of your Moon, Rising sign, and every planet in your cosmic dance.</p>
              <p className="subtitle">AI-Powered Insights, Uniquely Yours</p>
              <p>Our cutting-edge AI, guided by ancient wisdom, translates your complex chart into clear, personalized guidance. Uncover your strengths, challenges, and soul's purpose. Navigate relationships, career decisions, and personal growth with celestial clarity.</p>
              <p className="subtitle">Beyond Prediction, Towards Empowerment</p>
              <p>Starsoul.me is not about fortune-telling. It's about self-discovery. Your stars are not your destiny, but a map to your highest potential.  Let us illuminate your path with insights that inspire, motivate, and empower.</p>
              <p className="subtitle">Features</p>
              <List>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body1" component="span">
                        <b>Accurate Birth Chart Calculation:</b> Enter your precise birth details for the most accurate reading.
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body1" component="span">
                        <b>In-Depth Astrological Analysis:</b> Go beyond the basics with detailed interpretations of each planet and aspect.
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body1" component="span">
                        <b>Personalized Guidance:</b> Receive tailored advice on love, career, relationships, and personal growth.
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography variant="body1" component="span">
                        <b>Interactive Exploration:</b> Explore your chart visually, learn about different astrological concepts, and track your transits.
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
              <p className="subtitle">Try Me Out</p>
              <p>Try jeff@suchland.com / secretWord</p>
            </div>

            <Link to="/signup">
              <Button variant="contained" size="large">
                Begin Your Journey
              </Button>
            </Link>
            <br /><br />
            <Link to="/login">
              <Button variant="contained" size="large">
                Login
              </Button>
            </Link>
          </div>
        </Container>
        </Paper>
    </main>
  );
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState('');
  const [profileData, setProfileData] = useState({});
  const [my, setMy] = useState({});

  const dsetMy = (my) => {
    setMy(my);
    window.my = my; // debug
  }

  const handleLogin = (my) => {
    setIsLoggedIn(true);
    dsetMy(my);
  }

  const handleLogout = () => {
    setIsLoggedIn(false);
    setMy({});
  }

  const handleUpdateProfile = (newProfileData) => {
    setProfileData(newProfileData);
    // You might also want to save the updated data to an API here
  };

  return (
    <BrowserRouter>
      <div>
        {isLoggedIn ? <PostLogin my={my}/> : <PreLogin/>}

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/signup" element={<Signup setMy={dsetMy} />} />
          <Route path="/confirm" element={<Confirm my={my} onLogin={handleLogin} />} />
          <Route path="/login" element={<Login onLogin={handleLogin}/>} />
          <Route path="/logout" element={<Logout onLogout={handleLogout}/>} />
          <Route path="/profile" element={<Profile my={my}/>} />
          <Route path="/profile/edit" element={<EditProfile my={my} setMy={dsetMy}/>} />
          <Route path="/today" element={
            <Forecast my={my} setMy={dsetMy} when='today' />} />
          <Route path="/tomorrow" element={
            <Forecast my={my} setMy={dsetMy} when='tomorrow' />
          } />
          <Route path="/echo" element={<Echo />} />
          <Route path="/dashboard" element={<Dashboard my={my} />} />
        </Routes>
        <Footer>
        </Footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
