import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';

import {server_post, Error} from './Lib';
function Confirm({my, onLogin}) {
  const [token, setToken] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Send signup data to backend here
    console.log('Confirming:', my.id);

    let data = null, error = '';
    if (password !== password2) {
        setError("Passwords must match.");
        return; 
    }

    ({data, error} = await server_post('/api/confirm/',
                {token, password, pid:my.id}));
    if (error)
        setError(error);
    else {
        onLogin(data.my);
		navigate('/dashboard'); 
    }
  };

  return (
    <form className="fancyForm" onSubmit={handleSubmit}>
      <h2>Confirm Sign-up</h2>
      <Error error={error} />
      <input type="token" placeholder="Token" value={token} onChange={(e) => setToken(e.target.value)} />
      <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
      <input type="password" placeholder="Password Again" value={password2} onChange={(e) => setPassword2(e.target.value)} />
      <button type="submit">Signup</button>
    </form>
  );
}

export default Confirm;

